@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ffffff;
  --foreground: #171717;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Times New Roman', Times, serif;
  font-size: 18px; /* Larger base font size */
  line-height: 1.6;
  background-color: #f5f5f5;
}



@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
